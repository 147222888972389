import React from 'react';
import config from '../../Config';

interface SocialMediaData {
	link: string;
	iconSrc: string;
	iconStyle?: React.CSSProperties;
	title: string;
}

const socialMediaData: SocialMediaData[] = [
	{
		link: 'https://www.youtube.com/channel/UC09Tl85w1_5odYh78opAEdw',
		iconSrc: 'img/icon/youtube.svg',
		title: 'Youtube'
	},
	{
		link: 'https://www.behance.net/danygauthier1',
		iconSrc: 'img/icon/behance.svg',
		iconStyle: { height: '30px' },
		title: 'Behance'
	},
	{
		link: 'https://ca.linkedin.com/in/dany-gauthier-a487aa193',
		iconSrc: 'img/icon/linkedin.png',
		title: 'LinkedIn'
	},
	{
		link: 'https://www.artstation.com/cafetier',
		iconSrc: 'img/icon/artstation.svg',
		title: 'Artstation'
	},
	{
		link: 'https://github.com/VotreCafetier',
		iconSrc: 'img/icon/github.svg',
		title: 'Github'
	},
];

const Social = () => {
	return (
		<div id='other'>
			<h2>Socials</h2>
			<div>
				{socialMediaData.map((socialMedia, index) => (
					<a
						key={index}
						href={socialMedia.link}
						target='_blank'
						rel='noreferrer'
						title={socialMedia.title}
					>
						<img
							src={config.PREFIX_CDN + socialMedia.iconSrc}
							alt=''
							loading='lazy'
							style={socialMedia.iconStyle}
						/>
					</a>
				))}
			</div>
		</div>
	);
};

export default Social;
