interface Config {
	[key: string]: { [value: string]: string };
}

const config: Config = {
	development: {
		MAIL_API_URL: 'https://public.herotofu.com/v1/904968e0-7e69-11ee-8bd5-e178f08d9cb2',
		PREFIX_CDN: 'https://dgportfolioblob.blob.core.windows.net/portfolio/',
	},
	production: {
		MAIL_API_URL: 'https://public.herotofu.com/v1/904968e0-7e69-11ee-8bd5-e178f08d9cb2',
		PREFIX_CDN: 'https://dgportfolioblob.blob.core.windows.net/portfolio/',
	},
};

const env = process.env.NODE_ENV || 'development';
export default config[env as keyof Config];
