import ReactDOM from 'react-dom/client';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './index.css';
import i18n from './i18n';
import Home from './pages/Home';
import Error from './pages/Error';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
		errorElement: <Error />,
	},
]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<I18nextProvider i18n={i18n}>
		<RouterProvider router={router} />
	</I18nextProvider>
);
