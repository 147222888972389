import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import config from '../../Config';
import './Contact.css';

type FormData = {
	name: string;
	email: string;
	message: string;
};

const Contact = () => {
	const { t } = useTranslation();
	// State to manage form submission status
	const [submitting, setSubmitting] = useState(false);
	// Form handling with react-hook-form
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormData>();

	const onSubmit = async (data: FormData) => {
		setSubmitting(true);

		// Retry HTTP requests with axios
		axiosRetry(axios, {
			retryDelay: axiosRetry.exponentialDelay,
		});

		// Send POST request to mail API
		axios
			.post(config.MAIL_API_URL, data)
			.then(() => {
				toast.success(t('emailSuccessfull') as string);
				reset(); // reset form

				// Reset submission status
				setSubmitting(false);
			})
			.catch((e) => {
				setSubmitting(false);

				let er: any = e?.response;
				if (er?.status === 404) toast.error(t('email404') as string);
				else if (er?.status === 405) toast.error(er?.data as string);
				else if (er?.status === 500)
					toast.error(t('errorForm') as string);
				else
					toast.error(
						(er?.data as string) || (t('errorForm') as string)
					);
			});
	};

	return (
		<div id='contact'>
			<h2>{t('contact_me')}</h2>
			<form onSubmit={handleSubmit(onSubmit)}>
				{/* Name */}
				<div>
					<input
						type='text'
						{...register('name', {
							required: true,
							pattern: /^[\w]+$/i,
						})}
						placeholder={t('your_name') as string}
						aria-invalid={errors.name ? 'true' : 'false'}
						autoComplete='name'
					/>

					{errors.name && <p role='alert'>{t('errorNameValid')}</p>}
				</div>

				{/* From */}
				<div>
					<input
						type='email'
						{...register('email', {
							required: true,
							pattern: /^[\w@\w.\w]+$/i,
						})}
						placeholder={t('your_email') as string}
						aria-invalid={errors.email ? 'true' : 'false'}
						autoComplete='email'
					/>

					{errors.email && <p role='alert'>{t('errorEmailValid')}</p>}
				</div>

				{/* Message */}
				<div>
					<textarea
						cols={30}
						rows={10}
						{...register('message', { required: true })}
						placeholder='Description'
						aria-invalid={errors.message ? 'true' : 'false'}
					></textarea>

					{errors.message && (
						<p role='alert'>{t('errorDescription')}</p>
					)}
				</div>

				{/* Submit btn */}
				<button type='submit' disabled={submitting}>
					{submitting ? (
						<div className='custom-loader'></div>
					) : (
						t('send')
					)}
				</button>
			</form>
		</div>
	);
}

export default Contact;
