import React, { useLayoutEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import config from '../../../Config';

import './Card.css';

interface CardProps {
	image: {
		name?: string;
		alt?: string;
	};
	year: string;
	name: string;
	link?: string;
	key: number;
	description: string;
	index: number;
	softwares?: {
		id: string;
		name?: string;
	}[];
	placeholder?: boolean;
}

const Card = ({
	image,
	year,
	name,
	link,
	description,
	index,
	softwares = [],
	placeholder = true,
}: CardProps) => {
	const [cardVisible, setCardVisible] = useState(false);

	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.2,
	});

	useLayoutEffect(() => {
		if (inView) {
			let animationFrameId: number | null = null;
			const timeoutId = setTimeout(() => {
				animationFrameId = requestAnimationFrame(() =>
					setCardVisible(true)
				);
			}, 80 * index);

			return () => {
				clearTimeout(timeoutId);
				cancelAnimationFrame(animationFrameId!);
			};
		}
	}, [inView, index]);

	const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
		e.currentTarget.style.display = '';
		e.currentTarget.classList.remove('loading');
	};

	const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
		e.currentTarget.style.display = 'none';
		e.currentTarget.classList.replace('loading', 'missing');
	};

	return (
		<a
			ref={!placeholder ? ref : null}
			className={`card ${
				cardVisible ? 'animate__animated animate__fadeInUp ' : ''
			}${placeholder ? 'placeholder' : ''}`}
			href={link ?? '#'}
			target='_blank'
			rel='noreferrer'
		>
			{/* Image + trademark */}
			<div>
				{!placeholder && (
					<>
						<img
							src={`${
								config.PREFIX_CDN + "img/projects/" + image?.name ??
								'notFound'
							}`}
							loading='lazy'
							alt={image?.alt}
							onLoad={handleImageLoad}
							onError={handleImageError}
							className='loading'
						/>
						<div className='img'></div>
					</>
				)}

				<div className='trademark'>
					{softwares.map((e) => (
						<img
							key={e.id}
							src={
								config.PREFIX_CDN + 'img/icon/' + (e.name ?? '')
							}
							loading='lazy'
							alt={e.name}
							onError={handleImageError}
							onLoad={handleImageLoad}
							className='loading'
						/>
					))}
				</div>
			</div>
			<h5>{year}</h5>

			{/* Description Container */}
			<div>
				<h2>{name}</h2>
				<p>{description}</p>
			</div>
		</a>
	);
};

export default Card;
