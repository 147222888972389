import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import Card from './Card';

const Project = () => {
	const { t, i18n } = useTranslation();
	const languageCode = i18n.language.split('-')[0];
	const [projects, setProjects] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const source = axios.CancelToken.source();

		const fetchData = async () => {
			setLoading(true);

			axiosRetry(axios, {
				retryDelay: () => 1000,
			});

			axios
				.get(`/data/projects.${languageCode}.json`, {
					cancelToken: source.token,
				})
				.then((e) => {
					setProjects(e.data);
					// Reset submission status
					setLoading(false);
				})
				.catch((error) => {
					if (axios.isCancel(error)) return;
					// The request was not canceled, so handle the error
					setLoading(false);
					toast.error(t('errorProject'));
				});
		};
		fetchData();

		return () => {
			// Cancel the ongoing request
			source.cancel();
		};
		// eslint-disable-next-line
	}, [languageCode]);

	return (
		<div id='projects'>
			<h2>{t('projects')}</h2>
			{projects.length ? (
				projects.map((itemsCategory) => (
					<div key={itemsCategory.id}>
						<h2>{itemsCategory.category}</h2>
						<div className='grid-4'>
							{itemsCategory?.items.map(
								(project: any, index: number) => (
									<Card
										key={project.id}
										{...project}
										index={index}
										placeholder={false}
									/>
								)
							)}
						</div>
					</div>
				))
			) : (
				<div style={{ marginTop: '50px' }}>
					<div
						className={'grid-4' + (!loading ? ' noAnimation' : '')}
					>
						{[...Array(4)].map((_, i) => (
							<Card
								key={i}
								image={{
									name: undefined,
									alt: undefined,
								}}
								year={''}
								name={''}
								description={''}
								index={0}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
}

export default Project;
