import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
	return (
		<header id='header'>
			<h1 className='tracking-in-contract fade-in delay-1'>Portfolio</h1>
			<h3 className='fade-in delay-2 mt-2'>Dany Gauthier</h3>
			<FontAwesomeIcon
				icon={faCircleChevronDown}
				className='chevron-down fade-in delay-3'
			/>
		</header>
	);
};

export default Header;
