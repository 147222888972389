import React from 'react';
import { useTranslation } from 'react-i18next';

import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();

	const handleLanguageChange = () =>
		i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en');

	return (
		<input type='button'
		 id='language_switch'
		 onClick={handleLanguageChange}
		 value={i18n.language === 'en' ? 'fr' : 'en'}
		 />
	);
};

export default LanguageSwitcher;
