import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations for different languages
import enTranslation from './locales/en.json';
import frTranslation from './locales/fr.json';

// Initialize i18n
i18n.use(initReactI18next).init({
	resources: {
		en: { translation: enTranslation },
		fr: { translation: frTranslation },
	},
	lng: 'en',
	fallbackLng: 'en',
	interpolation: { escapeValue: false },
});

export default i18n;
