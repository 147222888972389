import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import Header from '../components/Header';
import Nav from '../components/nav/Nav';
import './Error.css';

const Error: React.FC = () => {
	const { t } = useTranslation();
	const error: any = useRouteError();

	return (
		<div id='error'>
			<div>
				<Header />
				<Nav />
			</div>
			<div>
				<h1>
					{process.env.NODE_ENV === 'dev'
						? error.statusText || error.message || t('errorGeneric')
						: t('errorGeneric')}
				</h1>
				<a href='/'>{t('returnMainPage')}</a>
			</div>
		</div>
	);
};

export default Error;
