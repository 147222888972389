import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LanguageSwitcher from './LanguageSwitcher';
import './Nav.css';

const Nav = () => {
	const { t } = useTranslation();
	const [scrolled, setScrolled] = useState(false);

	const navLinks = [
		{ id: 'projects', label: t('projects'), url: '#projects' },
		{ id: 'contact', label: 'Contact', url: '#contact' },
		{ id: 'socials', label: 'Socials', url: '#other' },
	];

	// HandleScrolls serves to show a dark overlay
	// when scrolling out of header (So full length of page)
	useEffect(() => {
		const handleScroll = () =>
			setScrolled(window.scrollY > window.innerHeight / 8);

		handleScroll();

		document.addEventListener('scroll', handleScroll);
		return () => document.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<nav className={`fade-in delay-3 ${scrolled ? 'scrolled' : ''}`}>
			{navLinks.map((link) => (
				<a key={link.id} href={link.url}>
					{link.label}
				</a>
			))}
			<LanguageSwitcher />
		</nav>
	);
};

export default Nav;
