import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';

import Header from '../components/Header';
import Nav from '../components/nav/Nav';
import './Home.css';
import { InView } from 'react-intersection-observer';

import Project from '../components/home/projects/Project';
import Contact from '../components/home/Contact';
import Social from '../components/home/Social';

const Home: React.FC = () => {
	const { i18n } = useTranslation();

	useLayoutEffect(() => {
		// append loaded class on body
		document.querySelector('body')?.classList.add('loaded');

		console.log(
			`Hello curious person.
      \nYes this website was build using React, and yes i can code.
      \nIf you want more :  https://github.com/Cafetier
      \nDany Gauthier
      `
		);
	}, []);

	useEffect(() => {
		// update the language to the browser default language
		const defaultLanguage = navigator.language.split('-')[0].toLowerCase();
		i18n.changeLanguage(defaultLanguage === 'fr' ? 'fr' : 'en');
	}, [i18n]);

	const pageItems = [<Project />, <Contact />, <Social />];

	return (
		<>
			<Header />
			<Nav />

			<section>
				{pageItems.map((Component, index) => (
					<InView
						className='items-animation'
						as='div'
						triggerOnce={true}
						key={index}
						onChange={(InView, entry) => {
							if (InView) entry.target.classList.add('show');
						}}
					>
						{Component}
					</InView>
				))}
			</section>

			{/* Footer */}
			<a href='#header'>
				<footer>{new Date().getFullYear()}</footer>
			</a>

			<ToastContainer
				position='bottom-right'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

export default Home;
